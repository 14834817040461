import Socials from '../Socials'
import './footer.css'
import AVI1 from '../../assets/6.svg'

const Footer = () => {
  return (
    <footer>
      <a className="footer__logo" href="https://www.youtube.com/watch?v=pqnKdgA39Zc" target="_blank" rel="noopener noreferrer"><img src={AVI1} alt="Develophants logo" /></a>

      <Socials className="footer__socials"/>

      <div className="footer__copyright">
        <small>&copy; Develophants LLC. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer