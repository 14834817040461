import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import AVI1 from '../../assets/6.svg'

import './testimonials.css'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import TestimonialsCard from './TestimonialsCard'

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Testimonials</h5>
      <h2>Our Clients</h2>
      <Swiper 
        className="container testimonials__container"
        modules={[Pagination, Navigation]}
        spaceBetween={40}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}

        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log('slide change')}
        >
        <SwiperSlide>
          <TestimonialsCard
            name="Nick S, Creative Director"
            alt="avatar"
            image={AVI1}
            review="The Develophants have helped me out a number of times. I would highly recommend them to anyone who needs good work done by a reliable team."
          />
        </SwiperSlide>
        <SwiperSlide>
          <TestimonialsCard
            name="Kenny H, Professor"
            alt="avatar"
            image={AVI1}
            review="This team is knowledgeable and easy to work with. They know how to find the right solutions to complex problems and get things done. 10/10."
          />
        </SwiperSlide>
        <SwiperSlide>
          <TestimonialsCard
            name="Nick W, CMO"
            alt="avatar"
            image={AVI1}
            review="Great at what they do and I trust them to delivery high quality work time and time again. Can't imagine working with anyone else on key digital projects."
          />
        </SwiperSlide>
        <SwiperSlide>
          <TestimonialsCard
            name="Vah Ruta, Divine Beast"
            alt="avatar"
            image={AVI1}
            review="Wow I don't know where I'd be without the Develophants. I'd completely lost my head until they came along."
          />
        </SwiperSlide>
      </Swiper>
    </section>
  )
}

export default Testimonials