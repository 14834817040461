import { useRef } from 'react';
import emailjs from 'emailjs-com';
import { MdOutlineMail } from 'react-icons/md'
import ContactCard from './ContactCard'
import { BsLinkedin } from 'react-icons/bs'

import './contact.css'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_mt5kbuh', 'template_zxlg7jg', form.current, 'w-vmBQhOLyWj7lGwK')
      .then((result) => {
          console.log(result.text);
          e.target.reset();
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <section id="contact">
      <h5>Contact Us</h5>
      <h2>We'd Love to Work With You</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <ContactCard
            icon={<MdOutlineMail />}
            title="Email"
            link="mailto:hello@develophants.com"
          />
          <ContactCard
            icon={<BsLinkedin />}
            title="LinkedIn"
            link="https://www.linkedin.com/company/develophants"
          />
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea name="message" rows="17" placeholder="Your Message" required></textarea>
          <button className="btn btn-primary" type="submit">Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact