const PortfolioCard = (props) => {
  return (
    <article className="portfolio__item">
      <div className="portfolio__item-image">
        <img src={props.image} alt={props.alt} />
      </div>
      <h3>{props.title}</h3>
      <div className="portfolio__item-cta">
        {/* <a href={props.github} className="btn" target="_blank" rel="noreferrer">
          Github
        </a> */}
        {props.website && (
          <a
            href={props.website}
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer"
          >
            Visit Site
          </a>
        )}
      </div>
    </article>
  );
};

export default PortfolioCard;
