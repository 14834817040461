import PHANT from '../../assets/8.svg'

import './header.css'

const Header = () => {

  return (
    <header>

      <div className="container header__container">
      <div className="header-right">
          <img id='header-img' src={PHANT} alt="develophants" />
        </div>
        <div className="header-left">
          <h1>A digital development studio focused on <span>building</span>, <span>launching</span>, and <span>scaling</span> world-class experiences for innovative brands</h1>
        </div>
      </div>
    </header>
  )
}

export default Header