
import './services.css'
import ServicesCard from './ServicesCard'
import ServicesCardListItem from './ServicesCardListItem'

const Services = () => {
  return (
    <section id="services">
        <h5>Services</h5>
        <h2>What We Do</h2>
      <div className="container services__container">
        <ServicesCard title="Full Stack Development">
          <ServicesCardListItem description="Utilize deep technical knowledge and bleeding-edge technologies on the front
and back ends to build highly performant, reliable, and scalable digital solutions to
your exact specifications"/>
        </ServicesCard>
        <ServicesCard title="Digital Strategy">
          <ServicesCardListItem description="Uncover the right digital strategies - from technical architecture to content - so your brand can deliver world-class digital experiences to your
consumers time and time again"/>
        </ServicesCard>
        <ServicesCard title="UI/UX Design">
          <ServicesCardListItem description="Use modern design best practices to help guide the ideation, creation, and iteration of your digital products, guaranteeing your ideas are brought to
life in the most impactful way possible"/>
        </ServicesCard>
        <ServicesCard title="Operations Management">
          <ServicesCardListItem description="Manage the ongoing operation of your digital touchpoints, ensuring best-in-class experiences are always
available for your consumers so you can focus on other areas of your business"/>
        </ServicesCard>
      </div>
    </section>
  )
}

export default Services