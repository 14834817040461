import PortfolioCard from "./PortfolioCard";
import BUd from "../../assets/portfolio/BU_desktop.png";
import BUm from "../../assets/portfolio/BU_mobile.png";
import HFd from "../../assets/portfolio/HF_desktop.png";
import HFm from "../../assets/portfolio/HF_mobile.png";
import KGd from "../../assets/portfolio/KG_desktop.png";
import KGm from "../../assets/portfolio/KG_mobile.png";

import "./portfolio.css";

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        <PortfolioCard
          title="Healthcare Foundry"
          image={HFd}
          alt="HealthcareFoundry.com"
          website="https://www.HealthcareFoundry.com/"
        />
        <PortfolioCard
          title="ByUs"
          image={BUd}
          alt="ByUs.media"
          website="https://www.ByUs.media/"
        />
        <PortfolioCard
          title="Kelly Green Lawn"
          image={KGd}
          alt="KellyGreenLawn.com"
          website="https://www.kellygreenlawn.com/"
        />
      </div>
    </section>
  );
};

export default Portfolio;
