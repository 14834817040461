const ContactCard = (props) => {
  return (
    <a href={props.link} target="_blank" rel="noreferrer" className="a-block">
      <div className="contact__option">
          <div className="contact__option-icon">
              {props.icon}
          </div>
          <h4 className='contact-copy'>{props.title}</h4>
      </div>
    </a>
  )
}

export default ContactCard