import { Children } from 'react';

export const ServicesCard = (props) => {
  return (
    <article className="service">
          <div className="service__head">
            <h3>{props.title}</h3>
          </div>
          <ul className="service__list">
            {Children.map(props.children, (x) => <li>{x}</li>)}
          </ul>
        </article>
  )
}

export default ServicesCard