const TestimonialsCard = (props) => {
  return (
    <article className="testimonial">
        <div className="client__avatar">
        <img src={props.image} alt={props.alt} />
        </div>
        <h5 className="client__name">{props.name}</h5>
        <small className="client__review">{props.review}</small>
    </article>
  )
}

export default TestimonialsCard