import { BsLinkedin } from 'react-icons/bs'
import { BsGithub } from 'react-icons/bs'

const Socials = (props) => {
  return (
    <div className={props.className}>
        <a 
        href="https://www.linkedin.com/company/develophants" target="_blank" rel="noreferrer">
            <BsLinkedin />
        </a>
        <a href="https://github.com/develophants" target="_blank" rel="noreferrer">
            <BsGithub />
        </a>

    </div>
  )
}

export default Socials