

const ServicesCardListItem = (props) => {
  return (
    <>
        <p>{props.description}</p>
    </>
  )
}

export default ServicesCardListItem