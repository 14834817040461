import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import GA4React from "ga-4-react";
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
const ga4react = new GA4React(process.env.REACT_APP_GA_MEASUREMENT_ID);

(async _ => {
  await ga4react.initialize()
  .then()
  .catch(err => console.log("Analytics Failure."))
  .finally(() => {
    root.render(
      <StrictMode>
        <App />
      </StrictMode>
    );
  });
})();


